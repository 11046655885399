import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { AFSpinner } from './AFSpinner';
import { MetricResponse } from './MarketData';
import { fetchWithSession } from './fetchWithSession';

export function GetMarketData({
  segment,
  subsegment,
}: {
  segment: string;
  subsegment: string;
}) {
  const [metric, setMetric] = useState<MetricResponse[]>();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [currentYear, setCurrentYear] = useState<number>(2022);

  async function getAISData(segment: string, subsegment: string) {
    const response = await fetchWithSession(
      `/ais-metrics?segment=${segment}&subsegment=${subsegment}`
    );
    return await response.json();
  }
  useEffect(() => {
    setDataLoaded(false);
    let dt = new Date();
    setCurrentYear(dt.getFullYear());
    getAISData(segment, subsegment)
      .then((ais) => {
        setMetric(ais.response);
        setDataLoaded(true);
      })
      .catch((error) => console.warn('error', error));
  }, [segment, subsegment]);

  return (
    <div id={`${segment}-${subsegment}-charts`}>
      <Container fluid>
        {!dataLoaded && <AFSpinner />}
        <div>
          {metric &&
            dataLoaded &&
            metric.map((e, k) => (
              <Row xs={1} md={3} lg={3} key={`${e.segment}-${k}`}>
                {e.metric.map((el, key) => (
                  <Col
                    key={`${e.subsegment}-${key}`}
                    style={{ marginBottom: '21px' }}
                  >
                    {
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={{
                          exporting: {
                            buttons: {
                              contextButton: {
                                menuItems: [
                                  'downloadPNG',
                                  'separator',
                                  'downloadXLS',
                                ],
                                theme: {
                                  fill: '#142E3D',
                                },
                                symbolFill: '#000000',
                              },
                            },
                            chartOptions: {
                              title: {
                                style: {
                                  color: 'black', // black title
                                },
                              },
                              subtitle: {
                                text: '',
                                style: {
                                  color: 'black',
                                },
                              },
                              plotOptions: {
                                pie: {
                                  dataLabels: {
                                    enabled: true,
                                    color: 'black',
                                  },
                                },
                                column: {
                                  dataLabels: {
                                    enabled: true,
                                    color: 'black',
                                  },
                                },
                              },
                              chart: {
                                backgroundColor: {
                                  linearGradient: [0, 0, 0, 400],
                                  stops: [
                                    [0, '#F0F0F0'],
                                    [1, '#FFFFFF'],
                                  ],
                                },
                                borderWidth: 0,
                                borderRadius: 15,
                                plotBackgroundColor: null,
                                plotShadow: false,
                                plotBorderWidth: 0,
                              },
                              legend: {
                                itemStyle: {
                                  color: '#CCC',
                                },
                                itemHoverStyle: {
                                  color: '#FFF',
                                },
                                itemHiddenStyle: {
                                  color: '#333',
                                },
                              },
                            },
                          },
                          chart: {
                            type: el.heading.includes('idle')
                              ? 'area'
                              : el.chartType,
                            zoomType: 'x',
                            backgroundColor: '#142E3D',
                            borderWidth: 0,
                            borderRadius: 0,
                            plotBackgroundColor: null,
                            plotShadow: false,
                            plotBorderWidth: 0,
                          },
                          plotOptions: {
                            pie: {
                              dataLabels: {
                                enabled: true,
                                format: '<b>{point.name}</b>: {point.y}',
                                color: '#ffffff',
                              },
                            },
                            column: {
                              borderWidth: 0,
                              pointPadding: 0,
                              stacking: 'stream',
                              dataLabels: {
                                enabled: false,
                              },
                            },
                            line: {
                              dataLabels: {
                                color: '#CCC',
                              },
                              marker: {
                                lineColor: '#333',
                              },
                            },
                            spline: {
                              marker: {
                                lineColor: '#333',
                              },
                            },
                            area: {
                              stacking: 'normal',
                              lineColor: '#fff',
                              lineWidth: 0.1,
                              marker: {
                                lineWidth: 0.1,
                                lineColor: '#fff',
                              },
                            },
                          },
                          colors: [
                            '#5787b2',
                            '#95D2CA',
                            '#c1c0c0',
                            '#8A84FF',
                            '#7FC2FB',
                            '#206273',
                            '#D46C75',
                            '#bfbfbf',
                            '#997D87',
                            '#0000A6',
                            '#63FFAC',
                            '#FF4A46',
                            '#5A0007',
                            '#809693',
                            '#FEFFE6',
                            '#1B4400',
                            '#4FC601',
                            '#3B5DFF',
                            '#4A3B53',
                            '#FF2F80',
                            '#61615A',
                            '#BA0900',
                            '#6B7900',
                            '#00C2A0',
                            '#FFAA92',
                            '#FF90C9',
                            '#B903AA',
                            '#D16100',
                            '#DDEFFF',
                            '#000035',
                            '#7B4F4B',
                            '#A1C299',
                          ],
                          xAxis: {
                            gridLineWidth: 0,
                            lineColor: '#999',
                            tickColor: '#999',
                            labels: {
                              style: {
                                color: '#999',
                                fontWeight: 'bold',
                              },
                            },
                            type: 'datetime',
                            title: {
                              style: {
                                color: '#AAA',
                                font: 'bold 12px Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif',
                              },
                            },
                          },
                          toolbar: {
                            itemStyle: {
                              color: '#CCC',
                            },
                          },
                          legend: {
                            itemStyle: {
                              color: '#CCC',
                              fontSize: '9px',
                              font: '9pt Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif',
                            },
                            itemHoverStyle: {
                              color: '#FFF',
                            },
                            itemHiddenStyle: {
                              color: '#333',
                            },
                          },
                          credits: {
                            style: {
                              fontSize: '15px',
                            },
                            enabled: true,
                            text: `© ${currentYear} Fearnleys`,
                            href: 'https://www.fearnleys.com',
                            position: {
                              align: 'left',
                              verticalAlign: 'top',
                              x: 100,
                              y: 100,
                            },
                          },
                          series: el.data.map(function (elem) {
                            return {
                              data: elem.timeSeries.map((element) => [
                                new Date(element.date).getTime(),
                                element.val,
                              ]),
                              name: elem.legend,
                              yAxis: elem.yAxis,
                            };
                          }),
                          title: {
                            text: el.heading,
                            style: {
                              color: '#FFF',
                              font: '16px Lucida Grande, Lucida Sans Unicode, Verdana, Arial, Helvetica, sans-serif',
                            },
                          },
                          tooltip: {
                            split: true,
                          },
                          yAxis: [
                            {
                              // Primary yAxis
                              alternateGridColor: null,
                              minorTickInterval: null,
                              gridLineColor: 'rgba(255, 255, 255, .1)',
                              lineWidth: 0,
                              tickWidth: 0,
                              labels: {
                                style: {
                                  color: '#999',
                                  fontWeight: 'bold',
                                },
                              },
                              title: {
                                text: el.heading.includes('%')
                                  ? '%'
                                  : el.heading.includes('speed')
                                  ? 'knots'
                                  : '# vessels',
                              },
                            },
                            {
                              // Secondary yAxis
                              alternateGridColor: null,
                              minorTickInterval: null,
                              gridLineColor: 'rgba(255, 255, 255, .1)',
                              lineWidth: 0,
                              tickWidth: 0,
                              labels: {
                                style: {
                                  color: '#999',
                                  fontWeight: 'bold',
                                },
                              },
                              title: {
                                text:
                                  el.data[1]?.yAxis === 1 ? '# vessels' : '',
                              },
                              opposite: true,
                            },
                          ],
                        }}
                      />
                    }
                  </Col>
                ))}
              </Row>
            ))}
        </div>
      </Container>
    </div>
  );
}
