import { Card, Col, Container, ListGroup, Row } from 'react-bootstrap';
import { SegmentMatch } from '../../state/segment';
import { AFSpinner } from '../AFSpinner';
import { useGetSharePointData } from './hooks/useGetSharePointData';
import News from './News';

export interface SharePointFolder {
  ServerRelativeUrl: string;
  Name: string;
  Folder: string;
}

function getNameOfFolderBySegment(segmentState: string): string[] {
  if (segmentState === SegmentMatch.DryCargo) {
    return ['Dry Bulk'];
  } else if (SegmentMatch.LNG === segmentState) {
    return ['LNG'];
  } else if (SegmentMatch.LPG === segmentState) {
    return ['LPG'];
  } else if (SegmentMatch.Tankers === segmentState) {
    return ['Tank'];
  } else {
    return ['Dry Bulk'];
  }
}

function getFilesForSegment(
  segmentState: string,
  apiFolder: SharePointFolder[]
): SharePointFolder[] {
  const validFolderArr: string[] = getNameOfFolderBySegment(segmentState); //segmentToSharePointFolder[segmentState];

  return apiFolder.filter((el) => {
    // Search the segments arr of valid folders and support other for all

    return (
      el.Folder.toLowerCase() === 'other' || validFolderArr.includes(el.Folder)
    );
  });
}

export function Index({
  segmentStateValue,
}: {
  segmentStateValue: string;
}): JSX.Element {
  // Code here is run when component is about to load
  const { sharePointLoaded, bunkerPricesLoaded, error, apiFolder } =
    useGetSharePointData(segmentStateValue);

  const loaded = sharePointLoaded && bunkerPricesLoaded; // joint loading state

  // Display: Error Page
  if (error)
    return (
      <div>
        <h1>Error! Something wrong happened</h1>
      </div>
    );

  // Display: Loading spinner
  if (!loaded) return <AFSpinner />;

  // Display: Loaded main app!
  return (
    <IndexUI apiFolder={apiFolder} segmentStateValue={segmentStateValue} />
  );
}

function IndexUI({
  apiFolder,
  segmentStateValue,
}: {
  apiFolder: SharePointFolder[] | undefined;
  segmentStateValue: string;
}): JSX.Element {
  return (
    <div className="middle-stuff">
      <Container fluid>
        <Row>
          {/* <Card bg="dark"> */}
          <div id="class">
            <div className="tradingview-widget-container-5"></div>
            <div className="tradingview-widget-container__widget"></div>
          </div>
          {/* </Card> */}
        </Row>
        <br />
        <Row md={4} sm={1} xs={1}>
          <Col>
            <Card bg="dark">
              <div id="class" className="twitter-container">
                <div className="twitter-mother"></div>
              </div>
            </Card>
          </Col>
          <Col>
            <Card bg="dark">
              <News />
            </Card>
            {/* <Card>
      <div id="class">
        <p className="text-dark" style={{fontFamily: 'roboto'}}>Bunker Prices</p>
        {loaded && bunkerPricesLoaded && <>
        <BunkerPrices data={bunkerPrices} />
        </>
        }
      </div>
    </Card> */}
            <div className="marginBox"></div>
          </Col>
          <Col>
            {/* <Card bg="dark"> */}
            <div className="tradingview-widget-container">
              <div className="tradingview-widget-container-6">
                <div className="tradingview-widget-container__widget"></div>
              </div>
            </div>
            {/* </Card> */}
            {/* <Card bg="light"> */}
            <div
              id="class"
              style={{ marginTop: '22px', backgroundColor: 'black!important' }}
            >
              <Card bg="dark" text="dark">
                <Card.Title style={{ margin: '10px' }}>
                  Latest research
                </Card.Title>
                <ListGroup variant="flush">
                  {!!apiFolder &&
                    getFilesForSegment(segmentStateValue, apiFolder).map(
                      (el, key) => {
                        return (
                          <ListGroup.Item
                            variant="dark"
                            action
                            href={el.ServerRelativeUrl}
                            target="new"
                            key={key}
                          >
                            {el.Name}
                          </ListGroup.Item>
                        );
                      }
                    )}
                </ListGroup>
              </Card>
            </div>
            {/* </Card> */}
          </Col>
          <Col>
            {/* <Card bg="light"> */}
            <div className="tradingview-widget-container">
              <div className="tradingview-widget-container-1">
                <div className="tradingview-widget-container__widget"></div>
              </div>
              <div className="marginBox"></div>
              <div className="tradingview-widget-container-2">
                <div className="tradingview-widget-container__widget"></div>
              </div>
              <div className="marginBox"></div>
              <div className="tradingview-widget-container-3">
                <div className="tradingview-widget-container__widget"></div>
              </div>
              <div className="marginBox"></div>
              <div className="tradingview-widget-container-4">
                <div className="tradingview-widget-container__widget"></div>
              </div>
            </div>
            {/* </Card> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
}
