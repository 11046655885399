import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useState, useEffect } from 'react';
import { Container, Row, Col, Tab, Nav, Table } from 'react-bootstrap';
import { AFSpinner } from './AFSpinner';
import { OverviewProps, CargoFlowResponse, numFormatter } from './CargoData';
import { fetchWithSession } from 'components/fetchWithSession';

Highcharts.setOptions({
  lang: {
    numericSymbols: ['k', 'M', 'B', 'T'],
  },
});

export const GetCargoCharts = (props: OverviewProps) => {
  const [data, setData] = useState<CargoFlowResponse[]>();
  const [dataLoaded, setDataLoaded] = useState(false);

  async function getCargoData(
    segment: string,
    timeUnit: 'MONTH' | 'QUARTER' | 'YEAR',
    tradeType: 'export' | 'import'
  ) {
    const response = await fetchWithSession(
      `/cargo-flow?segment=${segment}&timeUnit=${timeUnit}&tradeType=${tradeType}`
    );
    return await response.json();
  }
  useEffect(() => {
    setDataLoaded(false);
    getCargoData(props.segment, props.timeUnit, props.tradeType)
      .then((ais) => {
        setData(ais.response);
        setDataLoaded(true);
      })
      .catch((error) => console.warn('error', error));
  }, [props]);

  if (!dataLoaded) return <AFSpinner />;
  return (
    <div id={`${props.segment}-charts`} className="cargo-flow-content">
      <Container fluid>
        <Row>
          <Tab.Container
            id="cargo-tabs"
            defaultActiveKey={
              data !== undefined
                ? `${props.segment}-${
                    data[0] !== undefined ? data[0].name : ''
                  }-${props.timeUnit}-${props.tradeType}`
                : 'placeholder'
            }
          >
            <Col sm={2}>
              <Nav className="flex-column cargo-nav">
                {data &&
                  data.map((el, ke) => (
                    <Nav.Item key={`cargo-nav${ke}`}>
                      <Nav.Link
                        eventKey={`${props.segment}-${el.name}-${props.timeUnit}-${props.tradeType}`}
                      >
                        <img
                          src={el.flagUrl}
                          alt=""
                          style={{ height: '25px', marginRight: '10px' }}
                        />
                        {`${el.name}`}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
              </Nav>
            </Col>
            <Col sm={10}>
              <Tab.Content>
                {data &&
                  data.map((e, k) => (
                    <Tab.Pane
                      key={`${props.segment}-${e.name}-${props.timeUnit}-${props.tradeType}`}
                      eventKey={`${props.segment}-${e.name}-${props.timeUnit}-${props.tradeType}`}
                      title={`${e.name}`}
                    >
                      <Row>
                        <Col sm={9}>
                          <Row>
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={{
                                exporting: {
                                  buttons: {
                                    contextButton: {
                                      menuItems: [
                                        'downloadPNG',
                                        'separator',
                                        'downloadXLS',
                                      ],
                                      theme: {
                                        fill: '#142E3D',
                                      },
                                      symbolFill: '#000000',
                                    },
                                  },
                                  chartOptions: {
                                    title: {
                                      style: {
                                        color: 'black', // black title
                                      },
                                    },
                                    subtitle: {
                                      text: '',
                                      style: {
                                        color: 'black',
                                      },
                                    },
                                    plotOptions: {
                                      pie: {
                                        dataLabels: {
                                          enabled: true,
                                          color: 'black',
                                        },
                                      },
                                      column: {
                                        dataLabels: {
                                          enabled: true,
                                          color: 'black',
                                        },
                                      },
                                    },
                                    chart: {
                                      backgroundColor: {
                                        linearGradient: [0, 0, 0, 400],
                                        stops: [
                                          [0, '#F0F0F0'],
                                          [1, '#FFFFFF'],
                                        ],
                                      },
                                      borderWidth: 0,
                                      borderRadius: 15,
                                      plotBackgroundColor: null,
                                      plotShadow: false,
                                      plotBorderWidth: 0,
                                    },
                                    legend: {
                                      itemStyle: {
                                        color: '#CCC',
                                      },
                                      itemHoverStyle: {
                                        color: '#FFF',
                                      },
                                      itemHiddenStyle: {
                                        color: '#333',
                                      },
                                    },
                                  },
                                },
                                chart: {
                                  zoomType: 'x',
                                  backgroundColor: '#142E3D',
                                  borderWidth: 0,
                                  borderRadius: 0,
                                  plotBackgroundColor: null,
                                  plotShadow: false,
                                  plotBorderWidth: 0,
                                },
                                legend: {
                                  itemStyle: {
                                    color: '#CCC',
                                  },
                                  itemHoverStyle: {
                                    color: '#FFF',
                                  },
                                  itemHiddenStyle: {
                                    color: '#333',
                                  },
                                },
                                colors: [
                                  '#5787b2',
                                  '#95D2CA',
                                  '#c1c0c0',
                                  '#8A84FF',
                                  '#7FC2FB',
                                  '#206273',
                                  '#D46C75',
                                  '#bfbfbf',
                                  '#997D87',
                                  '#0000A6',
                                  '#63FFAC',
                                  '#FF4A46',
                                  '#5A0007',
                                  '#809693',
                                  '#FEFFE6',
                                  '#1B4400',
                                  '#4FC601',
                                  '#3B5DFF',
                                  '#4A3B53',
                                  '#FF2F80',
                                  '#61615A',
                                  '#BA0900',
                                  '#6B7900',
                                  '#00C2A0',
                                  '#FFAA92',
                                  '#FF90C9',
                                  '#B903AA',
                                  '#D16100',
                                  '#DDEFFF',
                                  '#000035',
                                  '#7B4F4B',
                                  '#A1C299',
                                ],
                                plotOptions: {
                                  column: {
                                    borderWidth: 0,
                                    pointPadding: 0,
                                    stacking: 'stream',
                                    dataLabels: {
                                      enabled: false,
                                    },
                                  },
                                },
                                series: [
                                  {
                                    data: e.timeSeries.map((e) => [
                                      new Date(e.date).getTime(),
                                      e.cargoes,
                                    ]),
                                    zoneAxis: 'x',
                                    zones: [
                                      {
                                        value:
                                          props.tradeType === 'export'
                                            ? new Date(
                                                new Date().getFullYear(),
                                                new Date().getMonth() - 3,
                                                1
                                              ).getTime()
                                            : new Date(
                                                new Date().getFullYear(),
                                                new Date().getMonth() - 1,
                                                1
                                              ).getTime(),
                                        color: '#5787b2',
                                      },
                                      {
                                        color: 'grey',
                                      },
                                    ],
                                    type: 'column',
                                    name:
                                      props.tradeType === 'export'
                                        ? '# loadings'
                                        : '# discharges',
                                    yAxis: 0,
                                  },
                                  {
                                    data: e.timeSeries.map((e) => [
                                      new Date(e.date).getTime(),
                                      e.dwtmiles,
                                    ]),
                                    type: 'spline',
                                    name: 'dwtmiles',
                                    yAxis: 1,
                                  },
                                ],
                                tooltip: {
                                  formatter(): string {
                                    let dateLabel = '';
                                    if (props.timeUnit === 'MONTH') {
                                      dateLabel = `${new Date(
                                        (this as any).x
                                      ).toLocaleDateString(undefined, {
                                        month: 'long',
                                      })} ${new Date(
                                        (this as any).x
                                      ).getFullYear()}`;
                                    }
                                    if (props.timeUnit === 'QUARTER') {
                                      dateLabel = `Q${Math.floor(
                                        (new Date((this as any).x).getMonth() +
                                          3) /
                                          3
                                      )} ${new Date(
                                        (this as any).x
                                      ).getFullYear()}`;
                                    }
                                    if (props.timeUnit === 'YEAR') {
                                      dateLabel = `${new Date(
                                        (this as any).x
                                      ).getFullYear()}`;
                                    }

                                    return `<div style="font-size: 10px"><strong>${dateLabel}</strong><br/><p>${
                                      (this as any).series.name
                                    }: ${(
                                      this as any
                                    ).y.toLocaleString()}</p></div>`;
                                  },
                                },
                                xAxis: {
                                  type: 'datetime',
                                  labels: {
                                    style: {
                                      color: '#999',
                                      fontWeight: 'bold',
                                    },

                                    formatter: function () {
                                      let dateLabel = '';

                                      if (props.timeUnit === 'MONTH') {
                                        dateLabel = Highcharts.dateFormat(
                                          '%b %Y',
                                          (this as any).value
                                        );
                                      }
                                      if (props.timeUnit === 'QUARTER') {
                                        dateLabel = `Q${Math.floor(
                                          (new Date(
                                            (this as any).value
                                          ).getMonth() +
                                            3) /
                                            3
                                        )} ${new Date(
                                          (this as any).value
                                        ).getFullYear()}`;
                                      }
                                      if (props.timeUnit === 'YEAR') {
                                        dateLabel = `${new Date(
                                          (this as any).value
                                        ).getFullYear()}`;
                                      }

                                      return dateLabel;
                                    },
                                  },
                                },
                                title: {
                                  text: `${e.name} ${props.tradeType}s`,
                                  style: {
                                    color: '#FFFFFF',
                                  },
                                },
                                subtitle: {
                                  text:
                                    props.tradeType === 'export'
                                      ? '<span>Trades from last quarter not finished</span>'
                                      : props.timeUnit === 'MONTH'
                                      ? '<span>Some lag for last months import</span>'
                                      : '',
                                },
                                yAxis: [
                                  {
                                    // Primary yAxis
                                    title: {
                                      text:
                                        props.tradeType === 'export'
                                          ? 'cargoes'
                                          : 'discharges',
                                    },
                                    labels: {
                                      style: {
                                        color: '#999',
                                        fontWeight: 'bold',
                                      },
                                    },
                                  },
                                  {
                                    // Secondary yAxis
                                    title: {
                                      text: 'dwtmiles',
                                    },
                                    labels: {
                                      style: {
                                        color: '#999',
                                        fontWeight: 'bold',
                                      },
                                    },
                                    opposite: true,
                                  },
                                ],
                                credits: {
                                  style: {
                                    fontSize: '15px',
                                  },
                                  enabled: true,
                                  text: `© ${new Date().getFullYear()} Fearnleys`,
                                  href: 'https://www.fearnleys.com',
                                  position: {
                                    align: 'left',
                                    verticalAlign: 'top',
                                    x: 100,
                                    y: 100,
                                  },
                                },
                              }}
                            />
                          </Row>
                          <Row style={{ marginTop: '10px' }}>
                            <Col sm={6}>
                              <Table className="fl-table" striped hover>
                                <thead>
                                  <tr>
                                    <th>
                                      Top{' '}
                                      {props.tradeType === 'export'
                                        ? 'load'
                                        : 'disch.'}{' '}
                                      {['Total', 'Other'].includes(e.name)
                                        ? [
                                            'vlcc',
                                            'suezmax',
                                            'aframax',
                                          ].includes(props.segment) &&
                                          e.name === 'Other'
                                          ? 'regions'
                                          : 'countries'
                                        : 'ports'}{' '}
                                      ({new Date().getFullYear() - 1})
                                    </th>
                                    <th style={{ textAlign: 'right' }}>
                                      {props.tradeType === 'export'
                                        ? 'Loadings'
                                        : 'Discharges'}{' '}
                                      ({new Date().getFullYear() - 1})
                                    </th>
                                    <th style={{ textAlign: 'right' }}>
                                      dwtmiles ({new Date().getFullYear() - 1})
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {props.tradeType === 'export' &&
                                    e.prevYearTopPorts.map(
                                      (port: any, k: any) => (
                                        <tr key={`${k}-export`}>
                                          <td>
                                            {port.loadcountry && (
                                              <img
                                                src={port.flagUrl}
                                                alt=""
                                                style={{
                                                  height: '20px',
                                                  marginRight: '8px',
                                                }}
                                              />
                                            )}
                                            {port.loadPort
                                              ? port.loadPort
                                              : port.loadcountry
                                              ? port.loadcountry
                                              : port.loadregion}
                                          </td>
                                          <td style={{ textAlign: 'right' }}>
                                            {port.cargoes &&
                                              port.cargoes.toLocaleString()}
                                          </td>
                                          <td style={{ textAlign: 'right' }}>
                                            {port.dwtmiles &&
                                              numFormatter(port.dwtmiles)}
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  {props.tradeType === 'import' &&
                                    e.prevYearTopPorts.map(
                                      (port: any, k: any) => (
                                        <tr key={`${k}-import`}>
                                          <td>
                                            {port.dischargecountry && (
                                              <img
                                                src={port.flagUrl}
                                                alt=""
                                                style={{
                                                  height: '20px',
                                                  marginRight: '8px',
                                                }}
                                              />
                                            )}
                                            {port.dischargePort
                                              ? port.dischargePort
                                              : port.dischargecountry
                                              ? port.dischargecountry
                                              : port.dischargeregion}
                                          </td>
                                          <td style={{ textAlign: 'right' }}>
                                            {port.cargoes
                                              ? port.cargoes.toLocaleString()
                                              : port.discharges &&
                                                port.discharges.toLocaleString()}
                                          </td>
                                          <td style={{ textAlign: 'right' }}>
                                            {port.dwtmiles &&
                                              numFormatter(port.dwtmiles)}
                                          </td>
                                        </tr>
                                      )
                                    )}
                                </tbody>
                              </Table>
                            </Col>
                            <Col sm={6}>
                              <Table className="fl-table" striped hover>
                                <thead>
                                  <tr>
                                    <th>
                                      Top{' '}
                                      {props.tradeType === 'export'
                                        ? 'disch.'
                                        : 'load'}{' '}
                                      {['vlcc', 'suezmax', 'aframax'].includes(
                                        props.segment
                                      ) && e.name === 'Other'
                                        ? 'regions'
                                        : 'countries'}{' '}
                                      ({new Date().getFullYear() - 1})
                                    </th>
                                    <th style={{ textAlign: 'right' }}>
                                      {props.tradeType === 'export'
                                        ? 'Discharges'
                                        : 'Loadings'}{' '}
                                      ({new Date().getFullYear() - 1})
                                    </th>
                                    <th style={{ textAlign: 'right' }}>
                                      dwtmiles ({new Date().getFullYear() - 1})
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {e.prevYearTopDischCountries !== undefined ? (
                                    e.prevYearTopDischCountries.map(
                                      (country: any, k: any) => (
                                        <tr key={`${k}-export`}>
                                          <td>
                                            <img
                                              src={country.flagUrl}
                                              alt=""
                                              style={{
                                                height: '20px',
                                                marginRight: '8px',
                                              }}
                                            />
                                            {country.dischargecountry
                                              ? country.dischargecountry
                                              : country.dischargeregion}
                                          </td>
                                          <td style={{ textAlign: 'right' }}>
                                            {country.discharges &&
                                              country.discharges.toLocaleString()}
                                          </td>
                                          <td style={{ textAlign: 'right' }}>
                                            {country.dwtmiles &&
                                              numFormatter(country.dwtmiles)}
                                          </td>
                                        </tr>
                                      )
                                    )
                                  ) : (
                                    <></>
                                  )}
                                  {e.prevYearTopLoadCountries !== undefined ? (
                                    e.prevYearTopLoadCountries.map(
                                      (country: any, k: any) => (
                                        <tr key={`${k}-import`}>
                                          <td>
                                            <img
                                              src={country.flagUrl}
                                              alt=""
                                              style={{
                                                height: '20px',
                                                marginRight: '8px',
                                              }}
                                            />
                                            {country.loadcountry}
                                          </td>
                                          <td style={{ textAlign: 'right' }}>
                                            {country.cargoes &&
                                              country.cargoes.toLocaleString()}
                                          </td>
                                          <td style={{ textAlign: 'right' }}>
                                            {country.dwtmiles &&
                                              numFormatter(country.dwtmiles)}
                                          </td>
                                        </tr>
                                      )
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </tbody>
                              </Table>
                            </Col>
                          </Row>
                        </Col>
                        <Col sm={3}>
                          <div
                            style={{ maxHeight: '70vh', overflow: 'scroll' }}
                            className="scroll-table"
                          >
                            <Table className="fl-table" striped hover>
                              <thead>
                                <tr>
                                  <th>{props.timeUnit}</th>
                                  <th style={{ textAlign: 'right' }}>
                                    {props.tradeType === 'export'
                                      ? 'Discharges'
                                      : 'Loadings'}
                                  </th>
                                  <th style={{ textAlign: 'right' }}>
                                    dwtmiles
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {e.timeSeries.map((row: any, key: any) => (
                                  <tr key={key}>
                                    <td>
                                      {props.timeUnit === 'YEAR'
                                        ? new Date(row.date).toLocaleDateString(
                                            undefined,
                                            { year: 'numeric' }
                                          )
                                        : props.timeUnit === 'QUARTER'
                                        ? `Q${Math.floor(
                                            (new Date(row.date).getMonth() +
                                              3) /
                                              3
                                          )} ${new Date(
                                            row.date
                                          ).toLocaleDateString(undefined, {
                                            year: 'numeric',
                                          })}`
                                        : new Date(row.date).toLocaleDateString(
                                            undefined,
                                            { month: 'short', year: 'numeric' }
                                          )}
                                    </td>
                                    <td style={{ textAlign: 'right' }}>
                                      {row.cargoes &&
                                        row.cargoes.toLocaleString()}
                                    </td>
                                    <td style={{ textAlign: 'right' }}>
                                      {row.dwtmiles &&
                                        numFormatter(row.dwtmiles)}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      </Row>
                    </Tab.Pane>
                  ))}
              </Tab.Content>
            </Col>
          </Tab.Container>
        </Row>
      </Container>
    </div>
  );
};
