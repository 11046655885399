export function makeScriptTag3_LPG() {
  const scriptTag3 = document.createElement('script');
  scriptTag3.src =
    'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
  scriptTag3.async = true;
  scriptTag3.innerHTML = `
            {
              "symbol": "CME:SP2!",
       "width": "100%",
       "height": "220",
       "locale": "en",
       "dateRange": "12M",
       "colorTheme": "dark",
       "trendLineColor": "#37a6ef",
       "underLineColor": "rgba(55, 166, 239, 0.15)",
       "isTransparent": false,
       "autosize": false,
       "largeChartUrl": ""
            }
            `;
  return scriptTag3;
}
