import { getAPIPath } from 'getAPIPath';
import React, { useEffect, useState } from 'react';
import { useSetNameAndEmail } from './useSetNameAndEmail';
import 'react-quill/dist/quill.snow.css';
import { MailUI } from './MailUI';
import { handleSubmitMail } from './handleSubmitMail';
import { fetchWithSession } from 'components/fetchWithSession';

export interface MailList {
  name: string;
  id: string;
}

export function sum(a: number, b: number) {
  return a + b;
}

export function Mailer({ segmentStateValue }: { segmentStateValue: string }) {
  const [selectedFile, setSelectedFile] = useState();
  const [subject, setSubject] = useState('');
  const [text, setText] = useState('');
  const [dropdownOptions, setdropdownOptions] = useState<MailList[]>();
  const [selectedMailList, setSelectedMailList] = useState<MailList>();
  const { senderEmail, senderName } = useSetNameAndEmail(segmentStateValue);
  const [isSentEmail, setIsSentEmail] = useState(false);

  const handleSelectDropdownItem = (e: any) => {
    setSelectedMailList(e.target.value);
  };

  const handleFileUpload = (e: any) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSubjectEntry = (e: any) => {
    setSubject(e.target.value);
  };

  useEffect(() => {
    const getMailLists = async () => {
      const apicall = await fetchWithSession('/getEmailsByUserEmail');
      const re = await apicall.json();

      const options = re.map((obj: any) => {
        return { name: obj.listName, id: obj.listId };
      });

      setdropdownOptions(options);
      setSelectedMailList(options[0]?.id as any);
    };
    getMailLists();
  }, []);

  const options =
    dropdownOptions &&
    dropdownOptions.map((e, index) => (
      <option key={index} value={e.id}>
        {e.name}
      </option>
    ));

  return (
    <>
      {/* TODO: få det til å se ut som at du sender en mail fra outlook. */}
      <MailUI
        isSentEmail={isSentEmail}
        senderEmail={senderEmail}
        subject={subject}
        handleSubjectEntry={handleSubjectEntry}
        handleSelectDropdownItem={handleSelectDropdownItem}
        options={options}
        handleFileUpload={handleFileUpload}
        handleSubmit={() =>
          handleSubmitMail(
            selectedFile,
            senderEmail,
            senderName,
            subject,
            text,
            selectedMailList,
            setIsSentEmail
          )
        }
        setText={setText}
        setSubject={setSubject}
        setIsSentEmail={setIsSentEmail}
      />
    </>
  );
}
