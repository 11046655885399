import FFACalc from 'components/FFACalc';
import { Mailer } from 'components/Mailer/Mailer';
import { useGetUserAndGetAcessRights } from 'components/useGetUserAndGetAcessRights';
import { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Segment, SegmentMatch } from 'state/segment';
import { BrowserRouter } from './BrowserRouter';
import { CargoData } from './components/CargoData';
import { FFATables } from './components/FFATables';
import { FleetData } from './components/FleetData';
import { FleetSearch } from './components/FleetSearch';
import { FuelPrices } from './components/FuelPrices';
import { Index } from './components/Index/Index';
import { LoginPage } from './components/Login';
import { MarketData } from './components/MarketData';
import { RateData } from './components/RateData';
import { useIsLogedIn } from './hooks/useIsLogedIn';
import { LayoutPage } from './LayoutPage';
import { NavPages, navPaths } from './routing';

export function getRouteUrlLink(segmentState: string, path: string): string {
  const urlAsString = segmentState.split(' ').join('').toLowerCase();
  const value = path.length > 1 ? `${path}/${urlAsString}` : `${urlAsString}`;

  // if (path === navPaths[NavPages.Main]) {
  //   return navPaths[NavPages.Main];
  // }

  return value;
}

export function getMatchRouteUrl(path: string): string {
  return `${path}/:segment`;
}

export function Root() {
  const { loading, loginStateVal } = useIsLogedIn();

  if (loading) {
    return <></>;
  }

  return (
    <>
      <BrowserRouter>
        <Routing loginStateVal={loginStateVal} />
      </BrowserRouter>
    </>
  );
}

function useSegmentState() {
  const [segmentStateValue, setSegmentStateValue] = useState<string>(
    SegmentMatch.DryCargo
  );

  const locationUrl = useLocation();
  const locationPathnameList = locationUrl.pathname.split('/');
  const match =
    locationPathnameList.length > 1
      ? locationPathnameList[locationPathnameList.length - 1]
      : Segment.DryCargo;

  useEffect(() => {
    const segmentFromUrl: string =
      match !== undefined ? match : Segment.DryCargo;

    const segmentStateValueToPass: string =
      segmentFromUrl !== SegmentMatch.DryCargo
        ? segmentFromUrl
        : SegmentMatch.DryCargo;

    setSegmentStateValue(segmentStateValueToPass);
  }, [match]);

  return { segmentStateValue };
}

function Routing({ loginStateVal }: { loginStateVal: Boolean }) {
  const { segmentStateValue } = useSegmentState();

  const { name, hasMailAccess } = useGetUserAndGetAcessRights(false);

  return (
    <Routes>
      {/* <Route
        path="/login"
        element={
          loginStateVal ? (
            <Navigate
              to={getRouteUrlLink(segmentStateValue, navPaths[NavPages.Main])}
            />
          ) : (
            <LoginPage />
          )
        } */}

      <Route path="/login" element={<LoginPage />}></Route>

      <Route
        path="/"
        element={
          loginStateVal ? (
            <Navigate
              to={getRouteUrlLink(segmentStateValue, navPaths[NavPages.Main])}
            />
          ) : (
            <LoginPage />
          )
        }
      ></Route>
      <Route
        path={getMatchRouteUrl(navPaths[NavPages.TradeIndicators])}
        element={
          loginStateVal ? (
            <LayoutPage segmentStateValue={segmentStateValue}>
              <MarketData segmentStateValue={segmentStateValue} />
            </LayoutPage>
          ) : (
            <Navigate to="/login" replace />
          )
        }
      ></Route>
      <Route
        path={getMatchRouteUrl(navPaths[NavPages.FleetData])}
        element={
          loginStateVal ? (
            <>
              <LayoutPage segmentStateValue={segmentStateValue}>
                <FleetData segmentStateValue={segmentStateValue} />
              </LayoutPage>
            </>
          ) : (
            <Navigate to="/login" replace />
          )
        }
      ></Route>
      <Route
        path={getMatchRouteUrl(navPaths[NavPages.FleetSearch])}
        element={
          loginStateVal ? (
            <LayoutPage segmentStateValue={segmentStateValue}>
              <FleetSearch segmentStateValue={segmentStateValue} />
            </LayoutPage>
          ) : (
            <Navigate to="/login" replace />
          )
        }
      ></Route>
      <Route
        path={getMatchRouteUrl(navPaths[NavPages.RatesAndAssetValues])}
        element={
          loginStateVal ? (
            <LayoutPage segmentStateValue={segmentStateValue}>
              <RateData segmentStateValue={segmentStateValue} />
            </LayoutPage>
          ) : (
            <Navigate to="/login" replace />
          )
        }
      ></Route>
      <Route
        path={getMatchRouteUrl(navPaths[NavPages.FFA])}
        element={
          loginStateVal ? (
            <LayoutPage segmentStateValue={segmentStateValue}>
              <FFACalc  segmentStateValue={segmentStateValue}/>
              <FFATables segmentStateValue={segmentStateValue} />
            </LayoutPage>
          ) : (
            <Navigate to="/login" replace />
          )
        }
      ></Route>
      <Route
        path={getMatchRouteUrl(navPaths[NavPages.BunkerPrices])}
        element={
          loginStateVal ? (
            <LayoutPage segmentStateValue={segmentStateValue}>
              <FuelPrices />
            </LayoutPage>
          ) : (
            <Navigate to="/login" replace />
          )
        }
      ></Route>
      <Route
        path={getMatchRouteUrl(navPaths[NavPages.CargoFlow])}
        element={
          loginStateVal ? (
            <LayoutPage segmentStateValue={segmentStateValue}>
              <CargoData segmentStateValue={segmentStateValue} />
            </LayoutPage>
          ) : (
            <Navigate to="/login" replace />
          )
        }
      ></Route>
      <Route
        path={getMatchRouteUrl(navPaths[NavPages.Main])}
        element={
          loginStateVal ? (
            <LayoutPage segmentStateValue={segmentStateValue}>
              <Index segmentStateValue={segmentStateValue} />
            </LayoutPage>
          ) : (
            <Navigate to="/login" replace />
          )
        }
      ></Route>
      <Route
        path={getMatchRouteUrl(navPaths[NavPages.Mailer])}
        element={
          // todo: add check for access to mailer component to make it possible to see route
          loginStateVal && hasMailAccess ? (
            <LayoutPage segmentStateValue={segmentStateValue}>
              <Mailer segmentStateValue={segmentStateValue} />
            </LayoutPage>
          ) : (
            <Navigate to="/login" replace />
          )
        }
      ></Route>
    </Routes>
  );
}
